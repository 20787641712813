import { FC } from 'react';
import useTranslation from 'next-translate/useTranslation';

import { EventType, MatchPeriod, PlatformID } from '@common/clients/api';
import { MatchEvent } from '@common/types';
import { getEventIcon } from '@web/atoms/match/utils';
import { TimelineMarker } from '@web/organisms/MatchTimeLine/molecules/TimelineMarker';

import { PlayerIDMap } from '../../PlayerIDMap';
import { FirstPlayer, SecondPlayer } from '../PlayerLink';

import styles from '../../MatchTimeLine.module.scss';

interface Props {
    event: MatchEvent;
    homeTeamID: string;
    penaltyScoresUntilNow: string;
    period: MatchPeriod;
    platform: PlatformID;
    playerIDMap: PlayerIDMap;
}

export const Event: FC<Props> = ({ event, homeTeamID, penaltyScoresUntilNow, platform, playerIDMap }) => {
    const isHomeTeam = 'teamID' in event && event.teamID === homeTeamID;

    const classes: string[] = [styles.event];
    isHomeTeam ? classes.push(styles.left) : classes.push(styles.right);

    const isDoubleRows =
        event.eventType === EventType.SUBSTITUTION || ('assistID' in event && event.assistID);
    if (isDoubleRows) classes.push(styles['double-rows']);

    let dataTimeScore = '';
    if (event.eventType === EventType.SHOOTOUT_PENALTY) {
        dataTimeScore = penaltyScoresUntilNow;
    } else if (event.formattedTime) {
        const timeObject = event.formattedTime;
        dataTimeScore =
            timeObject.minutes + "'" + (timeObject.overtime ? '+' + timeObject.overtime + "'" : '');
        if (timeObject.overtime) classes.push(styles['has-overtime']);
    }

    const __livescores = useTranslation('livescores').t;

    return (
        <div className={classes.join(' ')} data-time-score={dataTimeScore} key={event.id}>
            {platform === PlatformID.VI ? (
                <TimelineMarker score={dataTimeScore} align={isHomeTeam ? 'left' : 'right'} />
            ) : null}
            <div className={styles['event-content']}>
                {platform !== PlatformID.VI ? getEventIcon(event) : null}
                <div className={styles['name-rows']}>
                    <p className="first-player">
                        {platform === PlatformID.VI ? getEventIcon(event) : null}
                        {<FirstPlayer event={event} playerIDMap={playerIDMap} /> ||
                            __livescores`match.unknownPlayer`}
                    </p>
                    {isDoubleRows ? (
                        <p className="second-player">
                            {<SecondPlayer event={event} playerIDMap={playerIDMap} /> ||
                                __livescores`match.unknownPlayer`}
                        </p>
                    ) : null}
                </div>
            </div>
        </div>
    );
};
