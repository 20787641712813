import { FC, useMemo } from 'react';
import useTranslation from 'next-translate/useTranslation';

import {
    EventType,
    Match,
    MatchPeriod,
    MatchStatus,
    PeriodEventType,
    Player,
    ShootoutPenalty,
} from '@common/clients/api';
import { MatchEvent } from '@common/types';
import { useContextData } from '@common/useContextData';
import { DateTimeUtil, Format } from '@common/utils/DateTimeUtil';

import { Event } from './molecules/Event';
import { PlayerIDMap } from './PlayerIDMap';

import styles from './MatchTimeLine.module.scss';

function periodIDToTranslationKey(period: MatchPeriod): string {
    switch (period) {
        case MatchPeriod.FIRST_HALF:
            return 'match.stage.FirstHalf';
        case MatchPeriod.SECOND_HALF:
            return 'match.stage.SecondHalf';
        case MatchPeriod.EXTRA_TIME_FIRST_HALF:
            return 'match.stage.FirstExtraHalf';
        case MatchPeriod.EXTRA_TIME_SECOND_HALF:
            return 'match.stage.SecondExtraHalf';
        default:
            return '';
    }
}

export interface Props {
    match: Match;
}

const isShootoutPenalty = (event: MatchEvent): event is ShootoutPenalty => 'scored' in event;

export const MatchTimeLine: FC<Props> = ({ match }) => {
    const { platform } = useContextData();
    const platformID = platform.id;

    const playerIDMap = useMemo<PlayerIDMap>(() => {
        const allPlayers = [
            match?.details?.homeStartingPlayers ?? [],
            match?.details?.homeBenchPlayers ?? [],
            match?.details?.awayStartingPlayers ?? [],
            match?.details?.awayBenchPlayers ?? [],
        ].flat();

        return allPlayers.reduce((playerMap: PlayerIDMap = {}, player: Player) => {
            return { ...playerMap, [player.id]: player };
        }, {});
    }, [match?.details]);

    const __livescores = useTranslation('livescores').t;
    if (!match.details?.events) return null;
    let totalGoalsHome: number = 0;
    let totalGoalsAway: number = 0;

    const penaltyScores = match?.details?.events
        ?.filter((event) => {
            return event.eventType === EventType.SHOOTOUT_PENALTY;
        })
        .map((event) => {
            if (isShootoutPenalty(event) && event.scored) {
                event.teamID === match.home?.id ? totalGoalsHome++ : totalGoalsAway++;
            }
            return {
                id: event.id,
                scoreHome: totalGoalsHome,
                scoreAway: totalGoalsAway,
            };
        });

    let period = MatchPeriod.FIRST_HALF;

    const showTimeline = match.details?.events && playerIDMap;

    return (
        <div className={styles.MatchTimeLine}>
            {match.status === MatchStatus.PLAYED ? (
                <h3 className={styles['first-header']}>{__livescores`match.matchEnd`} </h3>
            ) : null}
            <div className={styles.timeline}>
                <hr />

                {showTimeline && match.details?.events.length
                    ? match.details?.events
                          .filter((event) => {
                              return !(
                                  event.eventType === EventType.PERIOD &&
                                  'periodEventType' in event &&
                                  (event.periodEventType === PeriodEventType.END ||
                                      event.period === MatchPeriod.FIRST_HALF)
                              );
                          })
                          .map((event) => {
                              //handling stages
                              if (event.eventType === EventType.PERIOD && event.period) {
                                  period = event.period;
                                  return (
                                      <h3 className={[styles['sub-header']].join(' ')} key={period}>
                                          {__livescores(periodIDToTranslationKey(period))}
                                      </h3>
                                  );
                              }

                              //handling penalties
                              let penaltyScoresUntilNow: string = '';
                              if (event.eventType === EventType.SHOOTOUT_PENALTY) {
                                  const penaltyItem = penaltyScores?.find((item) => item.id === event.id) || {
                                      id: '',
                                      scoreHome: 0,
                                      scoreAway: 0,
                                  };
                                  penaltyScoresUntilNow = penaltyItem.scoreHome + '-' + penaltyItem.scoreAway;
                              }

                              return (
                                  <Event
                                      key={event.id}
                                      event={event}
                                      homeTeamID={match.home?.id ? match.home?.id : ''}
                                      penaltyScoresUntilNow={penaltyScoresUntilNow}
                                      period={period}
                                      platform={platformID}
                                      playerIDMap={playerIDMap}
                                  />
                              );
                          })
                          .reverse()
                    : null}

                <hr />
            </div>
            <h3 className={styles['last-header']}>
                {__livescores`match.matchStart`}
                {' ' + DateTimeUtil.format(match.kickOff, Format.TIME_NUMERIC)}
            </h3>
        </div>
    );
};
