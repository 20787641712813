import { FC } from 'react';

import { Match } from '@common/clients/api';
import { DateTimeUtil } from '@common/utils/DateTimeUtil';
import { DetailsBlock as DetailsBlockBase } from '@web/molecules/DetailsBlock';

interface Props {
    match: Match;
}

export const DetailsBlock: FC<Props> = ({ match }) => {
    const { timeUnknown } = match;
    const date = match.kickOff ? new Date(match.kickOff) : undefined;
    const columns = {
        date: {},
        stadium: {},
        kickoff: {},
        referee: {},
    };

    const data = {
        date: timeUnknown ? '' : DateTimeUtil.format(date, DateTimeUtil.formats.DATE_FULL),
        kickoff: timeUnknown ? '' : DateTimeUtil.format(date, DateTimeUtil.formats.TIME_NUMERIC),
        stadium: match.details?.venueName || '',
        referee: match.details?.refereeName || '',
    };

    return <DetailsBlockBase columns={columns} data={data} />;
};
