import { FC } from 'react';

import { Shirt } from '@common/clients/api';

import styles from './Jersey.module.scss';

export interface DefaultShirtProps {
    showDefaultJerseys?: boolean;
    isHomeTeam?: boolean;
}

const getShirtProps = (props: Shirt | DefaultShirtProps): Shirt => {
    if ('showDefaultJerseys' in props) {
        const primaryColor = props.isHomeTeam ? '#009EE0' : '#231F20';

        return {
            pattern: '2111111111111111121211111111111111112',
            rotation: 30,
            primary: primaryColor,
            secondary: props.isHomeTeam ? '#007EB3' : '#414042',
            ribbing: primaryColor,
        };
    }
    return props as Shirt;
};

export const Jersey: FC<Shirt | DefaultShirtProps> = (props) => {
    const shirtProps = getShirtProps(props);

    const { pattern = '111', rotation = 0, primary, secondary, tertiary, ribbing, badge } = shirtProps;

    const colors = ['', primary, secondary, tertiary];

    const leftSleeveColor = pattern.charAt(0);
    const rightSleeveColor = pattern.charAt(pattern.length - 1);
    const bodyStripes = pattern.slice(1, -1); // Stripes in the middle

    const numberOfStripes = bodyStripes.length;
    const stripeWidth = 100 / numberOfStripes; // Calculate width for each stripe based on total number of stripes

    return (
        <span className={styles.Jersey}>
            <svg viewBox="0 0 24 20.54" fill="none" xmlns="http://www.w3.org/2000/svg">
                <defs>
                    <clipPath id="jersey-body-clip-path">
                        <path d="M18.91 8.06 15.55 0h-.05c0 1.33-1.36 3.5-3.55 3.5S8.45 1.57 8.45 0L5.09 8.06l-.62 11.62s2.21.86 7.49.86 7.49-.86 7.49-.86-.62-11.57-.62-11.62h.1Z" />
                    </clipPath>
                </defs>

                {/* Left Sleeve */}
                <path
                    id="left-sleeve"
                    d="m5.14 8.06-3.02.77L0 4.56 8.45 0 5.14 8.06Z"
                    fill={colors[+leftSleeveColor]}
                />

                {/* Right Sleeve */}
                <path
                    id="right-sleeve"
                    d="m18.86 8.06 3.02.77 2.11-4.27L15.55 0l3.31 8.06Z"
                    fill={colors[+rightSleeveColor]}
                    transform="translate(-0.05, 0)"
                />

                {/* Jersey Body Stripes */}
                <g clipPath="url(#jersey-body-clip-path)">
                    <g style={{ transform: `rotate(${rotation}deg)`, transformOrigin: '50% 50%' }}>
                        {[...bodyStripes].map((stripe, index) => (
                            <rect
                                key={`stripe-${index}`}
                                x={`${index * stripeWidth}%`} // Dynamically set the x-position based on stripe index
                                y="-12.5%" // Move stripes slightly upwards to account for rotation
                                width={`${stripeWidth}%`} // Dynamically set width based on number of stripes
                                height="125%" // Increase height to 125% to cover diagonal body during rotation
                                fill={colors[+stripe]}
                                stroke="none"
                                shapeRendering="crispEdges"
                            />
                        ))}
                    </g>
                </g>

                {/* Ribbing */}
                {ribbing ? (
                    <path
                        d="m3.12 8.59-1.01.24L0 4.56l.96-.53 2.16 4.56Zm17.76 0 1.01.24L24 4.56l-.96-.53-2.16 4.56ZM12 3.5c-1.97 0-3.55-1.58-3.55-3.5h1.1c0 1.3 1.1 2.35 2.4 2.35s2.4-1.06 2.4-2.35h1.15c0 1.92-1.58 3.5-3.55 3.5m.05 0c-1.97 0-3.55-1.58-3.55-3.5h1.1c0 1.3 1.1 2.35 2.4 2.35s2.4-1.06 2.4-2.35h1.15c0 1.92-1.58 3.5-3.55 3.5"
                        fill={ribbing}
                    />
                ) : null}

                {/* Badge */}
                {badge ? (
                    <path
                        d="M13.92 5.19s.51-.62 1.44-.62 1.44.62 1.44.62v1.96c0 .28-.15.53-.39.66l-1.05.57-1.05-.57a.745.745 0 0 1-.39-.66V5.19Z"
                        fill={badge}
                    />
                ) : null}
            </svg>
        </span>
    );
};
