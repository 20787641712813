import { FC } from 'react';

import { MatchEvent } from '@common/types';

import { PlayerIDMap } from '../../PlayerIDMap';
import { PlayerLink } from './PlayerLink';

export const FirstPlayer: FC<{ event: MatchEvent; playerIDMap: PlayerIDMap }> = ({ event, playerIDMap }) => {
    if ('ingoingPlayerID' in event && event.ingoingPlayerID) {
        return <PlayerLink playerID={event.ingoingPlayerID} playerIDMap={playerIDMap} />;
    } else if ('playerID' in event && event.playerID) {
        return <PlayerLink playerID={event.playerID} playerIDMap={playerIDMap} />;
    }
    return null;
};
