import { FC, useMemo } from 'react';

import { Icon } from '@common/atoms/Icon';
import { Player } from '@common/clients/api';
import { MatchEvent } from '@common/types';
import { PlayerHighlights } from '@web/atoms/match/types';
import { getCardIcon, getGoalsIcon, getPlayerHighlights } from '@web/atoms/match/utils';

import styles from './TeamSetup.module.scss';

interface Props {
    player: Player;
    events: MatchEvent[];
}

export const PlayerEvents: FC<Props> = ({ player, events }) => {
    const {
        playerGoalsCount,
        playerOwnGoalsCount,
        isPlayerSubstitutedOut,
        isPlayerSubstitutedIn,
        playerCardSeverity,
    }: PlayerHighlights = useMemo(() => getPlayerHighlights(events, player.id), [events, player.id]);

    return (
        <>
            <span className={styles['events']}>
                {playerCardSeverity ? <span>{getCardIcon(playerCardSeverity)}</span> : null}
                {isPlayerSubstitutedOut ? (
                    <span className={styles['substitution-event']}>
                        <Icon.switchOut />
                    </span>
                ) : null}
                {isPlayerSubstitutedIn ? (
                    <span className={styles['substitution-event']}>
                        <Icon.switchIn />
                    </span>
                ) : null}
                {playerGoalsCount > 0 || playerOwnGoalsCount > 0 ? (
                    <span>{getGoalsIcon(playerGoalsCount, playerOwnGoalsCount)}</span>
                ) : null}
            </span>
        </>
    );
};
