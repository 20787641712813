import { FC } from 'react';
import dynamic from 'next/dynamic';
import useTranslation from 'next-translate/useTranslation';

import { MatchStatistic, PlatformID, StatType } from '@common/clients/api';
import { ComponentHead } from '@web/molecules/ComponentHead';

import { Props as ChartProps } from './molecules/Chart';
import { StatLayout, StatsConfig, StatsDisplay } from './types';
import { prepareChartProps } from './utils';

const Chart = dynamic(() => import('./molecules').then((mod) => mod.Chart));

const STATS_CONFIG: StatsConfig = {
    [StatType.TOTAL_SHOTS]: {
        title: 'meta.totalShots',
        displayType: StatsDisplay.BAR_CHART,
    },
    [StatType.SHOTS_ON_GOAL]: {
        title: 'meta.shotsOnGoal',
        displayType: StatsDisplay.BAR_CHART,
    },
    [StatType.POSSESSION_PERCENTAGE]: {
        title: 'meta.possession',
        displayType: StatsDisplay.BAR_CHART,
    },
    [StatType.CORNERS]: {
        title: 'meta.corners',
        displayType: StatsDisplay.BAR_CHART,
    },
    [StatType.FOULS]: {
        title: 'meta.fouls',
        displayType: StatsDisplay.BAR_CHART,
    },
    [StatType.OFFSIDES]: {
        title: 'meta.offside',
        displayType: StatsDisplay.BAR_CHART,
    },
    [StatType.EXPECTED_GOALS]: {
        title: 'meta.expectedGoals',
        displayType: StatsDisplay.BAR_CHART,
    },
};

const VI_STATS_CONFIG: StatsConfig = {
    ...STATS_CONFIG,
    [StatType.POSSESSION_PERCENTAGE]: {
        title: 'title.ballPossession',
        displayType: StatsDisplay.PIE_CHART,
    },
};

const DEFAULT_LAYOUT: StatLayout = [
    {
        title: 'title.matchStatistics',
        layout: [
            StatType.TOTAL_SHOTS,
            StatType.SHOTS_ON_GOAL,
            StatType.POSSESSION_PERCENTAGE,
            StatType.CORNERS,
            StatType.FOULS,
            StatType.OFFSIDES,
        ],
    },
];

const VI_LAYOUT: StatLayout = [
    {
        title: 'title.ballPossession',
        // prettier-ignore
        layout: [
            StatType.POSSESSION_PERCENTAGE
        ],
    },
    {
        title: 'title.matchStatistics',
        layout: [
            StatType.TOTAL_SHOTS,
            StatType.SHOTS_ON_GOAL,
            StatType.CORNERS,
            StatType.FOULS,
            StatType.OFFSIDES,
        ],
    },
];

export interface Props {
    platform: PlatformID;
    statistics: MatchStatistic[];
}

export const MatchStatistics: FC<Props> = ({ platform, statistics }) => {
    const __stats = useTranslation('stats').t;

    const chartLayout = platform === PlatformID.VI ? VI_LAYOUT : DEFAULT_LAYOUT;
    const statsConfig = platform === PlatformID.VI ? VI_STATS_CONFIG : STATS_CONFIG;

    const statisticGroups = prepareChartProps(chartLayout, statistics, statsConfig);

    return (
        <>
            {statistics.length
                ? statisticGroups.map((group, index) => {
                      if (group.layout.length === 0) return null;

                      const isSingleChart = group.layout.length === 1;
                      const showSectionTitle = platform === PlatformID.VI && !isSingleChart;

                      const sectionTitle = isSingleChart ? group.layout[0].title : group.title;

                      return (
                          <section key={`stats-${index}`}>
                              {showSectionTitle ? (
                                  <ComponentHead title={__stats(sectionTitle)} titleBorder={'main-color'} />
                              ) : null}
                              {group.layout.map((chartProps: ChartProps<string>, index) => {
                                  const { awayValue, displayType, homeValue, title } = chartProps;

                                  const showFancyChartTitle = platform === PlatformID.VI && isSingleChart;

                                  return (
                                      <Chart
                                          key={`chart-${index}`}
                                          awayValue={awayValue}
                                          displayType={displayType}
                                          homeValue={homeValue}
                                          title={
                                              showFancyChartTitle ? (
                                                  <ComponentHead
                                                      title={__stats(title)}
                                                      titleBorder={'main-color'}
                                                  />
                                              ) : (
                                                  __stats(title)
                                              )
                                          }
                                      />
                                  );
                              })}
                          </section>
                      );
                  })
                : null}
        </>
    );
};
