import { FC } from 'react';

import { MatchEvent } from '@common/types';

import { PlayerIDMap } from '../../PlayerIDMap';
import { PlayerLink } from './PlayerLink';

export const SecondPlayer: FC<{ event: MatchEvent; playerIDMap: PlayerIDMap }> = ({ event, playerIDMap }) => {
    if ('outgoingPlayerID' in event && event.outgoingPlayerID) {
        return <PlayerLink playerID={event.outgoingPlayerID} playerIDMap={playerIDMap} />;
    } else if ('assistID' in event && event.assistID) {
        return <PlayerLink playerID={event.assistID} playerIDMap={playerIDMap} />;
    }
    return null;
};
