import { FC, useMemo } from 'react';

import { Icon } from '@common/atoms/Icon';
import { PlatformID, Player, Shirt } from '@common/clients/api';
import { MatchEvent } from '@common/types';
import { useContextData } from '@common/useContextData';
import { InternalLink, Route } from '@web/atoms/InternalLink';
import { Jersey } from '@web/atoms/match/Shirt';
import { PlatformToggle } from '@web/molecules/PlatformToggle';
import { Alignment, Columns, Row, StatsTable } from '@web/molecules/StatsTable';

import { PlayerEvents } from './PlayerEvents';

import styles from './TeamSetup.module.scss';

export interface Props {
    players?: Player[];
    title?: string;
    events?: MatchEvent[];
    isCoach?: boolean;
    shirt?: Shirt;
    isHomeTeam?: boolean;
}

export const TeamSetup: FC<Props> = ({ players, title, events = [], isCoach, shirt, isHomeTeam }) => {
    const { platform } = useContextData();

    const total: Row = {};

    const columns: Columns = {
        ...(platform.id === PlatformID.VI
            ? {
                  shirts: {
                      title,
                      class: 'minimum-width',
                      headerColSpan: 3,
                  },
              }
            : {
                  rank: {
                      title,
                      class: 'color-black minimum-width',
                      headerColSpan: 3,
                  },
              }),
        name: {
            class: 'color-black',
            alignment: platform.id === PlatformID.VI ? undefined : Alignment.Left, // Adjust based on platform
        },
        icons: {
            alignment: Alignment.Right,
            class: 'minimum-width',
        },
    };

    const data = useMemo(
        () =>
            Object.values(players || []).map((player) => ({
                shirts: (
                    <PlatformToggle include={PlatformID.VI}>
                        <span className={styles.shirt}>
                            {isCoach ? (
                                <Icon.coach />
                            ) : shirt ? (
                                <Jersey {...shirt} />
                            ) : (
                                <Jersey showDefaultJerseys={true} isHomeTeam={isHomeTeam} />
                            )}
                        </span>
                    </PlatformToggle>
                ),
                rank: player.shirtNumber,
                name: player.tag ? (
                    <InternalLink route={Route.Player} query={{ tag: player.tag.tagSlug }}>
                        {player.name}
                    </InternalLink>
                ) : (
                    player.name
                ),
                icons: (
                    <span className={styles.events}>
                        {events?.length ? <PlayerEvents player={player} events={events} /> : null}
                    </span>
                ),
            })),
        [events, isCoach, isHomeTeam, players, shirt],
    );

    return (
        <section className={styles.TeamSetup}>
            <StatsTable columns={columns} data={data} total={total} />
        </section>
    );
};
