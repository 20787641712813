import { StatType } from '@common/clients/api';

export enum StatsDisplay {
    BAR_CHART = 'bar-chart',
    PIE_CHART = 'pie-chart',
}

export type Stats = Record<StatType, number>;

export type StatConfig = {
    title: string;
    displayType?: StatsDisplay;
};

export type StatsConfig = Record<StatType, StatConfig>;

export type Layout = {
    title: string;
    layout: StatType[];
};

export type StatLayout = Layout[];
