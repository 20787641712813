import { MatchStatistic, StatType } from '@common/clients/api';
import { StatLayout, StatsConfig } from '@web/organisms/MatchStatistics/types';

import { Props as ChartProps } from './molecules/Chart';

const filterChartStats = (layout: StatType[], statistics: MatchStatistic[], statsConfig: StatsConfig) => {
    return layout.reduce((layoutConfig: ChartProps<string>[], statType: StatType) => {
        const statistic = statistics.find((stat) => stat.type === statType);

        if (statistic) {
            const updatedConfig = {
                ...statsConfig[statType],
                homeValue: statistic?.homeValue ? Math.round(statistic.homeValue) : 0,
                awayValue: statistic?.awayValue ? Math.round(statistic.awayValue) : 0,
            };

            return [...layoutConfig, updatedConfig];
        }

        return layoutConfig;
    }, []);
};

export const prepareChartProps = (
    chartLayout: StatLayout,
    statistics: MatchStatistic[],
    layoutConfig: StatsConfig,
) => {
    return chartLayout.map(({ title, layout }) => ({
        title,
        layout: filterChartStats(layout, statistics, layoutConfig),
    }));
};
