import { FC } from 'react';

import { InternalLink, Route } from '@web/atoms/InternalLink';

import { PlayerIDMap } from '../../PlayerIDMap';

export const PlayerLink: FC<{ playerID: string | undefined; playerIDMap: PlayerIDMap }> = ({
    playerID,
    playerIDMap,
}) => {
    if (!playerID) {
        return null;
    }
    const player = playerIDMap[playerID];
    if (!player) {
        return null;
    }
    if (player.tag) {
        return (
            <InternalLink route={Route.Player} query={{ tag: player.tag.tagSlug }}>
                {player.name}
            </InternalLink>
        );
    }
    return player.name;
};
