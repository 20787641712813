import type { ReactElement } from 'react';

import { Icon } from '@common/atoms/Icon';
import { Card, CardSeverity, EventType, GoalType, MatchStatus, PlatformID } from '@common/clients/api';
import { MatchEvent } from '@common/types';
import { PlayerHighlights } from '@web/atoms/match/types';
import { IconWithCounter } from '@web/molecules/IconWithCounter';

export const getShowMatchScores = (matchStatus?: MatchStatus): boolean => {
    return !!matchStatus && [MatchStatus.PLAYING, MatchStatus.PLAYED].indexOf(matchStatus) >= 0;
};

export const getEventIcon = (event: MatchEvent): ReactElement => {
    let ChosenIcon = Icon.minusCircle;

    if (event.eventType === EventType.GOAL && 'goalType' in event) {
        switch (event.goalType) {
            case GoalType.GOAL:
                ChosenIcon = event.disapproved ? Icon.cancelledGoal : Icon.football;
                break;
            case GoalType.OWN_GOAL:
                ChosenIcon = event.disapproved ? Icon.cancelledOwnGoal : Icon.footballRed;
                break;
            case GoalType.PENALTY_GOAL:
                ChosenIcon = event.disapproved ? Icon.penaltyDisapproved : Icon.penaltyScored;
                break;
            case GoalType.NO_GOAL:
                ChosenIcon = event.disapproved ? Icon.cancelledOwnGoal : Icon.cancelledGoal;
                break;
        }
    } else if (event.eventType === EventType.CARD && 'severity' in event) {
        switch (event.severity) {
            case CardSeverity.YELLOW_CARD:
                ChosenIcon = event.disapproved ? Icon.yellowCardCancelled : Icon.yellowCard;
                break;
            case CardSeverity.SECOND_YELLOW_CARD:
                ChosenIcon = event.disapproved ? Icon.secondYellowCardCancelled : Icon.secondYellowCard;
                break;
            case CardSeverity.RED_CARD:
                ChosenIcon = event.disapproved ? Icon.redCardCancelled : Icon.redCard;
                break;
        }
    } else {
        switch (event.eventType) {
            case EventType.SUBSTITUTION:
                ChosenIcon = Icon.substitution;
                break;

            case EventType.PENALTY_MISSED:
                ChosenIcon = Icon.penaltyMissed;
                break;

            case EventType.CARD:
                break;

            case EventType.SHOOTOUT_PENALTY:
                ChosenIcon = 'scored' in event && event.scored ? Icon.penaltyScored : Icon.penaltyMissed;
                break;
        }
    }

    return <ChosenIcon />;
};

/** List of card severities */
const CARD_SEVERITIES = Object.values(CardSeverity);
export const getPlayerCardSeverity = (playerCards: Card[]): CardSeverity | undefined => {
    let cardSeverity: CardSeverity | undefined;
    CARD_SEVERITIES.map((severity) => {
        const severityCards = playerCards.filter((card) => card.severity === severity);
        const disapprovedCards = severityCards.filter((card) => card.disapproved);
        const approvedSeverityCardsCount = severityCards.length - disapprovedCards.length;

        if (approvedSeverityCardsCount > 0) {
            cardSeverity = severity;
        }
    });
    return cardSeverity;
};

export const getPlayerHighlights = (events: MatchEvent[], playerID: string): PlayerHighlights => {
    let playerGoalsCount: number = 0;
    let playerOwnGoalsCount: number = 0;
    const playerCards: Card[] = [];

    events.forEach((event) => {
        if (
            event.eventType === EventType.GOAL &&
            'goalType' in event &&
            event.playerID === playerID &&
            !event.disapproved
        ) {
            const goalType = event.goalType;
            if (goalType === GoalType.OWN_GOAL) {
                playerOwnGoalsCount++;
            } else {
                playerGoalsCount++;
            }
        }

        if (event.eventType === EventType.CARD && 'playerID' in event && event.playerID === playerID) {
            playerCards.push(event);
        }
    });

    const playerCardSeverity: CardSeverity | undefined = getPlayerCardSeverity(playerCards);

    const isPlayerSubstitutedOut: boolean = events.some(
        (event) =>
            event.eventType === EventType.SUBSTITUTION &&
            'outgoingPlayerID' in event &&
            event.outgoingPlayerID === playerID,
    );

    const isPlayerSubstitutedIn: boolean = events.some(
        (event) =>
            event.eventType === EventType.SUBSTITUTION &&
            'ingoingPlayerID' in event &&
            event.ingoingPlayerID === playerID,
    );

    return {
        playerGoalsCount,
        playerOwnGoalsCount,
        playerCardSeverity,
        isPlayerSubstitutedOut,
        isPlayerSubstitutedIn,
    };
};

export const getGoalsIcon = (
    playerGoalsCount: number,
    playerOwnGoalsCount: number,
    platformID?: PlatformID,
) => {
    if (!playerGoalsCount && !playerOwnGoalsCount) return <></>;
    const icon = playerGoalsCount > 0 ? <Icon.football /> : <Icon.footballRed />;
    if (playerGoalsCount === 1) playerGoalsCount = 0;

    return (
        <IconWithCounter
            icon={icon}
            badgeText={playerGoalsCount}
            badgeSize={'tiny'}
            badgeBackground={platformID === PlatformID.VI ? 'main' : 'fourth'}
            badgePosition={'top-right'}
        />
    );
};

export const getCardIcon = (playerCardSeverity?: CardSeverity) => {
    if (!playerCardSeverity) return <></>;

    const cardSeverity: CardSeverity = playerCardSeverity;
    let cardIcon = <></>;
    switch (cardSeverity) {
        case CardSeverity.RED_CARD:
            cardIcon = <Icon.redCard />;
            break;
        case CardSeverity.SECOND_YELLOW_CARD:
            cardIcon = <Icon.secondYellowCard />;
            break;
        case CardSeverity.YELLOW_CARD:
            cardIcon = <Icon.yellowCard />;
            break;
    }

    return cardIcon;
};
