import { FC } from 'react';

import styles from './TimelineMarker.module.scss';

interface Props {
    score: string;
    align: 'left' | 'right';
}

export const TimelineMarker: FC<Props> = ({ score, align }) => {
    return (
        <div className={`${styles.TimelineMarker} ${styles[align]}`}>
            <span className={styles['event-time']}>{score}</span>
            <span className={styles['event-circle']}></span>
        </div>
    );
};
